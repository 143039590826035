import React from "react";
import styled, { consolidateStreamedStyles } from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import format from "date-fns/format";
import { colors, fonts } from "../style-utilities/variables";
import Arrow from "./Arrow";
import ButtonGrey from "./ButtonGrey";

const StyledWidget = styled.div`
  margin-top: 2em;
  .news-container {
    border: 2px solid ${colors.secondaryGray};
    padding: 18px;
    h4 {
      margin: 10px 10px 0;
      font-family: ${fonts.header};
      text-transform: uppercase;
    }
    .gray-button {
      display: inline-block;
      background-color: white;
      letter-spacing: 3px;
      width: auto;
      cursor: pointer;
      margin-top: 0.5em;
      text-transform: uppercase;
      text-align: center;
      border: 1px solid ${colors.lightGray};
      border-radius: 25px;
      letter-spacing: 3px;
      padding: 13px 25px;
      font-size: 14px;
      color: ${colors.black};
      text-decoration: none;
      white-space: nowrap;
      font-weight: bold;

      :hover {
        border: 1px solid ${colors.secondaryRed};
        background-color: ${colors.secondaryRed};
        transition: 0.3s all ease;
        text-decoration: none;
        color: ${colors.white};
      }
    }
    .widget-box {
      display: flex;
      justify-content: space-between;
      padding: 14px 10px;
      text-align: left;
      border-bottom: 2px solid ${colors.secondaryGray};
      padding-bottom: 10px;
      .title {
        margin-right: 10px;
        a {
          color: black;
          font-family: ${fonts.subHead};
          letter-spacing: 0.02em;
        }
      }
    }

    .widget-box:first-of-type {
      padding-top: 20px;
    }

    .widget-box:last-of-type {
      border-bottom: none;
      padding-bottom: 1.5em;
    }
    p {
      margin-bottom: 8px;
      color: black;
      font-weight: bold;
    }
  }
`;

const FeaturedPressWidget = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpNews {
          edges {
            node {
              content
              date
              slug
              title
              better_featured_image {
                source_url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const pressList = data.allWordpressWpNews.edges;
      pressList.length = 5;
      return (
        <StyledWidget>
          <div className="news-container">
            <h4>What We&rsquo;ve Been Up To</h4>
            {pressList.map(({ node, index }) => {
              return (
                <div className="widget-box">
                  <p className="title">
                    <Link to={"about/news/" + node.slug}>{node.title}</Link>
                  </p>
                  <p className="arrow-link">
                    <Link to={"about/news/" + node.slug}>
                      <Arrow />
                    </Link>
                  </p>
                </div>
              );
            })}
            <Link to="/about/news" className="gray-button">
              See All Company News
            </Link>
          </div>
        </StyledWidget>
      );
    }}
  />
);

export default FeaturedPressWidget;
