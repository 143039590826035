import React, { Fragment, Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import format from 'date-fns/format';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';

import NewsGrid from '../components/News/NewsGrid';
import ArrowLeft from '../components/ArrowLeft';
import Arrow from '../components/Arrow';
import Hero from '../components/Hero';
import MediaContactWidget from '../components/MediaContactWidget';
import ContactBanner from '../components/ContactBanner';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';
import ButtonRed from '../components/ButtonRed';
import FeaturedNewsWidget from '../components/FeaturedNewsWidget';

const StyledNewsPosts = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  overflow-x: hidden;
  .desktop {
    display: none;
  }
  .media-contact {
    height: 18rem;
  }
  .center {
    text-align: center;
    a {
      margin: 0 0 3em;
    }
    h2 {
      margin: 2em 0 1.5em;
    }
  }
  .post-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 2rem;
    .btns-number-container {
      justify-content: flex-end;
    }
  }
  .awards-container {
    .award {
      max-width: 210px;
      padding: 10px;
      text-align: center;
      margin: 0 auto;
      a {
        color: black;
      }
      .img-cont {
        max-width: 200px;
        margin: 0 auto;
        padding: 0 10px;
        img {
          min-width: 140px;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .press-grid {
      width: 66.6%;
      margin-right: 2em;
    }
    .right {
      width: 33.3%;
    }
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
    .post-area {
      display: flex;
      justify-content: space-between;
    }
    .center {
      a {
        margin: 2.5em 0 3em;
      }
      h2 {
        margin: 4em 0 1.5em;
      }
    }
    .awards-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

class PressRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsToShow: 6
    };
  }

  showMore() {
    this.setState({ itemsToShow: this.state.itemsToShow + 2 });
  }

  render() {
    let posts_page = this.props.data.wordpressPage;
    let posts = this.props.data.allWordpressWpPress.edges;
    return (
      <StyledNewsPosts className="animate">
        <Helmet>
          {/* General tags */}
          <title>Company Press Room | Ansira</title>
          <meta
            name="description"
            content="See the latest company news from Ansira, the leading marketing technology and services firm."
          />
          <meta name="image" content={DefaultSharingImage} />

          {/* OpenGraph tags */}
          <meta property="og:url" content="/about/press-room" />
          <meta property="og:type" content="website" />

          <meta property="og:title" content="Company Press Room | Ansira" />
          <meta
            property="og:description"
            content="See the latest company news from Ansira, the leading marketing technology and services firm."
          />
          <meta property="og:image" content={DefaultSharingImage} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="https://twitter.com/ansira" />
          <meta name="twitter:title" content="Company Press Room | Ansira" />
          <meta
            name="twitter:description"
            content="See the latest company news from Ansira, the leading marketing technology and services firm."
          />
          <meta name="twitter:image" content={DefaultSharingImage} />
        </Helmet>
        <Hero headline="Press Room" sub="Shout-outs for Ansira." />
        <div className="page-container">
          <div className="post-area">
            <NewsGrid posts={posts} itemsToShow={this.state.itemsToShow} />
            {posts.length > this.state.itemsToShow ? (
              <div className="mobile center">
                <ButtonRed
                  clickFunction={this.showMore.bind(this)}
                  textLabel="Show More"
                />
              </div>
            ) : (
              <></>
            )}
            <div className="right">
              <MediaContactWidget
                className="media-contact"
                bgcolor={colors.white}
                textcolor={colors.primary}
              />
              <FeaturedNewsWidget />
            </div>
          </div>

          <div className="center">
            {posts.length > this.state.itemsToShow ? (
              <div className="desktop">
                <ButtonRed
                  clickFunction={this.showMore.bind(this)}
                  textLabel="Show More"
                />
              </div>
            ) : (
              <></>
            )}
            <h2>Kudos to Us</h2>
          </div>

          <div className="awards-container">
            {posts_page.acf.awards.map(item => {
              return item.award_link ? (
                <div className="award">
                  <a className="award-link" href={item.award_link}>
                    <div className="img-cont">
                      <img src={item.award_image.source_url} />
                    </div>
                    <h4>{item.award_headline}</h4>
                  </a>
                  {ReactHtmlParser(item.award_copy)}
                </div>
              ) : (
                <div className="award">
                  <div className="img-cont">
                    <img src={item.award_image.source_url} />
                  </div>
                  <h4>{item.award_headline}</h4>
                  {ReactHtmlParser(item.award_copy)}
                </div>
              );
            })}
          </div>
          <ContactBanner
            headline={posts_page.acf.contact_headline}
            cta={posts_page.acf.contact_cta}
            url={posts_page.acf.contact_url}
          />
        </div>
      </StyledNewsPosts>
    );
  }
}

export default PressRoom;

export const query = graphql`
  query {
    allWordpressWpPress(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          content
          excerpt
          type
          date(formatString: "MMMM D, YYYY")
          better_featured_image {
            source_url
          }
          acf {
            link
          }
        }
      }
    }
    wordpressPage(slug: { eq: "press-room" }) {
      type
      acf {
        awards {
          award_headline
          award_copy
          award_link
          award_image {
            source_url
          }
        }
        contact_headline
        contact_cta
        contact_url
      }
    }
  }
`;
